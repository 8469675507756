// @flow

// import React, { useEffect } from 'react';
import React from 'react';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './charge-outside-ua.module.scss';
import Section from '../../components/Section';

import metaValues from '../../../config';
import countries from './data.json';

// const scroll = Scroll.animateScroll;

const seoText = {
  ua: 'Шукаєте зарядні станції для електромобілів в {COUNTRY}? На цій сторінці ви знайдете детальну інформацію про розташування зарядних станцій, типи зарядних пристроїв, їх вартість та умови використання. Також представлені карти та рекомендації для зручного планування маршруту. Ми надаємо інформацію українською, англійською, польською та німецькою мовами, щоб зробити пошук зарядних станцій зручним для всіх мандрівників',
  en: 'Looking for charging stations for electric vehicles in {COUNTRY}? On this page you will find detailed information about the location of charging stations, types of charging devices, their cost and terms of use. You will also find maps and recommendations for easy route planning. We provide information in Ukrainian, English, Polish and German to make the search for charging stations convenient for all travelers',
  pl: 'Szukasz stacji ładowania dla pojazdów elektrycznych w {COUNTRY}? Na tej stronie znajdziesz szczegółowe informacje o lokalizacji stacji ładowania, rodzajach urządzeń ładowania, ich kosztach i warunkach użytkowania. Znajdziesz tu również mapy i zalecenia dotyczące łatwego planowania trasy. Udostępniamy informacje w języku ukraińskim, angielskim, polskim i niemieckim, aby ułatwić wyszukiwanie stacji ładowania wszystkim podróżnym',
  de: 'Suchen Sie nach Ladestationen für Elektrofahrzeuge in {COUNTRY}? Auf dieser Seite finden Sie detaillierte Informationen über den Standort von Ladestationen, die Art der Ladegeräte, ihre Kosten und Nutzungsbedingungen. Sie finden hier auch Karten und Empfehlungen für eine einfache Routenplanung. Wir stellen Informationen auf Ukrainisch, Englisch, Polnisch und Deutsch zur Verfügung, um die Suche nach Ladestationen für alle Reisenden bequem zu gestalten',
  ru: 'Ищете зарядные станции для электромобилей в {COUNTRY}? На этой странице вы найдете подробную информацию о расположении зарядных станций, типах зарядных устройств, их стоимости и условиях использования. Также представлены карты и рекомендации для удобного планирования маршрута. Мы предоставляем информацию на украинском, английском, польском и немецком языках, чтобы сделать поиск зарядных станций удобным для всех путешественников'
};

const seoTitles = {
  ua: {
    title: 'Зарядка електроавто, де заряджатися, мережі зарядок - '
  },
  ru: {
    title: 'Зарядка электромобилей, где зарядиться, зарядные станции - '
  },
  en: {
    title: 'Charging networks, operators and charging stations - '
  },
  de: {
    title: 'Laden von Elektroautos, Ladestationen, wo man laden kann - '
  },
  pl: {
    title:
      'Ładowanie samochodów elektrycznych, gdzie ładować, stacje ładowania - '
  }
};

const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noopener,noreferrer');
  // const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  // if (newWindow) {
  //   newWindow.opener = null;
  // }
};

const renderItem = (item: any) => {
  const { title, text, url } = item;

  return (
    <li>
      <h3>{title}</h3>
      {text.map(oneRow => (
        <p>{oneRow}</p>
      ))}
      {url && (
        <div className={styles.url} onClick={() => openInNewTab(url)}>
          {url}
        </div>
      )}
    </li>
  );
};

const renderVignette = (vignette: any, language: string) => {
  if (!vignette) {
    return null;
  }

  const vignetteTitle = {
    ua: 'Проїзд по дорогах',
    ru: 'Проезд по дорогам',
    de: 'Fahrt auf der Straße',
    en: 'Driving on the road',
    pl: 'Jazda po drodze'
  };

  const source =
    vignette[language] && vignette[language].length
      ? vignette[language]
      : vignette;

  const lines = source.join('\n');
  const seoHead = _.get(vignetteTitle, language) || vignetteTitle.ua;

  return (
    <div>
      <h4>{seoHead}</h4>
      <div
        className={styles.vignette}
        dangerouslySetInnerHTML={{ __html: lines }}
      />
    </div>
  );
};

// https://docs.google.com/document/d/1Kfy2SGQCPioWDUDyiWluBiVA3RNgs3G-_f5D3Qed2K4/edit#heading=h.1jkfszmfqnl5

const OutsideScreen = (): any => {
  const { currCountry = 'ukraine' } = useParams();
  const common = useSelector(({ common: comm }: any) => comm);
  const { language } = common;
  const history = useNavigate();

  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;
  const currCountryInfo = countries.find(({ slug }) => slug === currCountry);

  if (!currCountryInfo && currCountry !== 'ukraine') {
    history(`/${language}/charge-out-ukraine`);
    return null;
  }

  const seoTitle = seoTitles[language]?.title || seoTitles.ua.title;
  const seoHeadText = (seoText[language] || '').replace(
    '{COUNTRY}',
    _.get(currCountryInfo, `language.${language}`)
  );

  return (
    <section className={styles.section}>
      <Helmet>
        <title>
          {seoTitle} {currCountryInfo.language[language]}
        </title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={defaultTitle} />
      </Helmet>

      <Section>
        <ul className={styles.countryList}>
          {countries.map(country => {
            const current = currCountry === country.slug ? styles.current : '';
            return (
              <li className={current} key={country.slug}>
                <Link to={`/${language}/charge-out-ukraine/${country.slug}`}>
                  {country.language[language]}
                </Link>
              </li>
            );
          })}
        </ul>

        {!currCountry && (
          <div>
            <p>Please, choose the country what you`re interesting</p>
            <p>
              If general information that you looking is absent - message to us
              and we`ll add that to this section
            </p>
          </div>
        )}

        {countries.map(({ language: langSEO, items, vignette, slug }) => {
          const isVisible = currCountry === slug;
          if (!isVisible) {
            return null;
          }

          return (
            <div>
              <h2>{langSEO[language]}</h2>
              {seoHeadText && <p>{seoHeadText}</p>}
              {renderVignette(vignette, language)}
              {items && items.length > 0 && (
                <div>
                  <h3>Charging networks</h3>
                  <ul>{items.map(renderItem)}</ul>
                </div>
              )}
            </div>
          );
        })}
      </Section>
    </section>
  );
};

export default OutsideScreen;

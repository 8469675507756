const translate = {
  _HOME_HEAD_TITLE: 'Die Welt emissionsfrei entdecken',
  _HOME_HEAD_BUTTON1: 'Nach abgeschlossenen Reisen suchen',
  _HOME_HEAD_BUTTON2: 'Beginnen Sie mit der Planung Ihrer Reise',

  _HOME_HEAD_SECOND_TITLE: `Laden erkunden`,
  _HOME_HEAD_SECOND_DESC: `<p>Verwenden Sie ein Konto für alle wechselnden Netzwerke in Ihrer Nähe!</p><p>Mit unserer tollen App können Sie Ihr Auto ganz einfach finden, anschließen, bezahlen und aufladen – alles, was Sie dazu brauchen, ist</p>`,

  _HOME_HEAD_THIRD_TITLE: `Sparen Sie Ihre <span>ZEIT</span>`,
  _HOME_HEAD_FOURTH_TITLE: `und <span>GELD</span>`,

  _HOME_WOW:
    `<p>Wir helfen Elektroautos dabei, eine nächstgelegene Ladestation in der gesamten Ukraine und in Europa zu finden und dieses eine Konto in allen Ladenetzen zu verwenden.` +
    `<p>Sie können die letzten Ladevorgänge anzeigen, um zu erkennen, wann diese Station das letzte Mal von anderen Fahrzeugen genutzt wurde – das hilft Ihnen, den Besuch nicht funktionierender Stationen auf der Straße zu vermeiden.</p>`,

  _HOME_STATIONS: `Stationen`,
  _HOME_OUTSIDE_UKRAINE: `Laden in Europa`,
  _HOME_FIND_NEAR: `Ladegerät in Ihrer Nähe finden`,

  _MAP_ROUTE_ADD_ROUTE_POINT: 'Routenpunkt hinzufügen',
  _MAP_ROUTE_ADD_ROUTE_TEXT:
    '<p>Neuer Punkt wird oben auf der Route hinzugefügt und später können Sie eine Punktkette anordnen</p>',
  _MAP_ROUTE_POWER_LIMIT: `Leistungslimit:`,
  _MAP_ROUTE_DISTANCE_LIMIT: `Entfernungslimit:`,
  _MAP_ROUTE_DISTANCE_LIMIT_TEXT:
    'Maximale Entfernung zur Station von der Route (km)',
  _MAP_ROUTE_DISTANCE_CHARGE_LIMIT: `Entfernung von Gebühr zu Gebühr:`,
  _MAP_ROUTE_DISTANCE_CHARGE_LIMIT_TEXT:
    'Durchschnittliche Entfernung zwischen Ladestationen (km)',
  _MAP_ROUTE_OPTIONS: 'Optionen:',
  _MAP_ROUTE_OPTION_1: 'Kostenlose Stationen bevorzugen',
  _MAP_ROUTE_OPTION_2: 'Maut vermeiden',
  _MAP_ROUTE_OPTION_3: 'Vermeide autobahnen',

  _CLEAR_ROUTE: 'Neue Route',
  _NO_ROUTES: 'Sie haben keine gespeicherten Routen',
  _SAVE_ROUTE: 'Route speichern',
  _SAVE_ROUTE_TEXT:
    'Die gespeicherte Route kann später geladen und geändert werden. Sie können die Route mit allen Haltestellen und Informationen zu den Gebühren speichern.',
  _PAGE_HEAD_TITLE: 'Routenkarten-Builder',
  _MAP_ROUTE_LOGGED:
    '<h1>Achtung!</h1><p>Sie müssen sich anmelden, um diesen Abschnitt nutzen zu können</p>',
  _LOAD: 'Laden',
  _SAVE: 'Speichern',
  _LOAD_TITLE: 'Route laden',
  _LOAD_DESC:
    'Hier können Sie ganz einfach jede gespeicherte Route laden.“ Wählen Sie einfach die Route aus und klicken Sie auf',

  _POINTS: 'Punkte:',
  _POINTS_TEXT: 'Sie können die Reihenfolge der Punkte ziehen und ändern',
  _PREMIUM_ACCOUNT: 'Premium-Konto',
  _PREMIUM_ACCOUNT_SEO_TITLE: 'Vorteile und Kosten des Premium-Kontos',
  _PREMIUM_ACCOUNT_TEXT: 'Vorteile für Premium-Benutzer',
  _PREMIUM_FEATURES: 'Funktionen',
  _PREMIUM_REGULAR_USERS: 'Normale Benutzer',
  _PREMIUM_PREMIUM_USERS: 'Premium-Benutzer',

  // Header

  _LOGOUT: 'Abmelden',
  _ACCOUNT: 'Konto',
  _REGULAR_ACCOUNT: 'Reguläres Konto',
  _MY_TRIPS: 'Meine Reise',
  _TRIP_PLANNER: 'Reiseplaner',
  _MY_STATIONS: 'Meine Stationen',
  _MY_ENERGY: 'Meine Energie',
  _MY_TARIFFS: 'Meine Tarife',
  _CHARGING: 'Laden',
  _EV_FRIENDS: 'EV-Freunde',
  _EV_HOTELS: 'EV-Hotels',
  _STATIONS_MAP: 'Stationskarte',

  // Stories
  _STORIES: 'Meine Reisen:',
  _STORIES_TEXT:
    'In diesem Abschnitt können Sie mit anderen Nutzern Ihr EV-Reiseerlebnis in anderen Ländern oder Städten mit Punkten teilen, an denen Sie übernachten oder kostenlos aufladen können',
  _ADD_STORY: 'Reise hinzufügen',
  _EDIT_STORY: 'Reise bearbeiten',
  _STORIES_HEAD_TITLE: 'Meine Reisen',
  _STORY_EDIT: 'Bearbeiten',
  _PUBLISHED_STATUS: 'Veröffentlicht',
  _UNPUBLISHED_STATUS: 'Unveröffentlicht',
  _CHARGING_POINTS: 'Ladepunkte',

  _ERROR: 'Fehler',
  _EDIT: 'Bearbeiten',
  _EDIT_TRIP: 'Reise bearbeiten',
  _ADD_TRIP: 'Reise hinzufügen',
  _CANCEL: 'Abbrechen',
  _ADD_POINT: 'Punkt hinzufügen',
  _CHARGE_POINT: 'Ladepunkt',
  _BACK_TO_TRIPS: 'Zurück zu Reisen',
  _ALLOW_USERS_TO_SEE: 'Anderen Benutzern erlauben, diese Reise zu sehen',
  _UPLOAD_BUTTON: 'Dateien auswählen',
  _ADD_DESCRIPTION: 'Beschreibung hinzufügen',
  _UPLOAD_TEXT: 'Unterstützte Dateien zum Hochladen – JPG, PNG, JPEG',

  _LOCATION_POSITION: 'Gefundene Position:',
  _ERROR_GEO:
    'Aus irgendeinem Grund können wir Ihren Standort nicht verfolgen, können ihn aber manuell eingeben',
  _IS_CHARGING_FREE: 'Das Aufladen war für mich kostenlos',
  _ADD_CHARGE_POINT: 'Ladepunkt hinzufügen',
  _MAX_CHARGING_SPEED: 'Maximale Ladegeschwindigkeit:',

  _DELETE: 'Löschen',
  _FROM: 'Von',
  _TO: 'An',
  _DURATION: 'Dauer',
  _TOTAL_DISTANCE: 'Gesamtdistanz',
  _TOTAL_DURATION: 'Gesamtdauer',

  _SHOW_MAP: 'Karte anzeigen',
  _HIDE_MAP: 'Karte verstecken',
  _SHOW: 'Anzeigen',
  _EV_TRIPS: 'EV-Fahrten'
};

export default translate;
